var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto h-full w-full bg-zinc-800",attrs:{"id":"dashboard"}},[_c('Modal',{attrs:{"open":_vm.rulesModalOpen,"title":"How to Play"},on:{"close":_vm.rulesModalToggle}},[_c('Rules')],1),_c('div',{staticClass:"fixed top-0 left-0 z-20 h-auto w-full overflow-auto bg-zinc-800 pt-3 shadow-lg shadow-zinc-800/50"},[_c('DashboardHeader',{attrs:{"visibleGameweek":_vm.visibleGameweek,"activeLeague":_vm.activeLeague,"fixtures":_vm.fixtures,"panelOpen":_vm.leaguePanelOpen},on:{"toggleLeaguePanel":_vm.toggleLeaguePanel,"onGameweekSwitch":_vm.onGameweekSwitch}}),(_vm.leaguePanelOpen)?_c('DashboardLeagueSwitcher',{attrs:{"canSwitchLeagues":_vm.canSwitchLeagues,"activeLeague":_vm.activeLeague,"canShare":_vm.canShare},on:{"switchLeague":_vm.switchLeague,"share":_vm.share,"howToPlay":_vm.rulesModalToggle}}):_vm._e(),_c('div',{staticClass:"relative mt-4 border-b border-t border-b-white/10 border-t-slate-900"})],1),_c('div',{class:[
      'flex',
      'justify-between',
      'mx-6',
      'relative',
      'z-10',
      'transition-all',
      'md:max-w-xl',
      'md:mx-auto',
      ("" + (_vm.leaguePanelOpen ? 'pt-[177px]' : 'pt-[110px]')) ]},[_c('DashboardFigure',{attrs:{"figure":_vm.loading ? '--' : _vm.gameweekPoints,"icon":"star","text":"GW Points"}}),_c('DashboardFigure',{attrs:{"figure":_vm.loading ? '--' : _vm.totalPoints,"icon":"medal","text":"Total Points"}}),_c('router-link',{attrs:{"to":{ name: 'scoreboard' }}},[_c('DashboardFigure',{attrs:{"figure":_vm.loading ? '--' : _vm.leaguePosition,"icon":"chart-line","text":"Position","icon-right":"arrow-right"}})],1)],1),_c('div',{staticClass:"my-6 px-3"},[(_vm.showSetPredictionsButton)?_c('Button',{attrs:{"text":"Set Predictions","icon-right":"arrow-right"},on:{"click":function($event){return _vm.$router.push({
          name: 'set-predictions',
          params: {
            gameweek: _vm.visibleGameweek,
            can_use_bonus_coin_default: _vm.canUseBonusCoin,
            can_use_bonus_lock_default: _vm.canUseBonusLock,
          },
        })}}}):_vm._e()],1),_c('div',{staticClass:"relative z-10 mt-3 min-h-full overflow-auto rounded-t-2xl bg-white py-2 text-center shadow"},[(_vm.loading || _vm.refreshing)?_c('fa',{staticClass:"mt-16 text-zinc-800",attrs:{"icon":"circle-notch","size":"2x","spin":""}}):_vm._e(),(!_vm.loading && !_vm.refreshing)?_c('DashboardFixtures',{attrs:{"fixtures":_vm.fixtures,"predictions":_vm.predictions,"gameweek":_vm.visibleGameweek,"can_use_bonus_coin":_vm.canUseBonusCoin,"can_use_bonus_lock":_vm.canUseBonusLock}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }