<template>
  <div class="container mx-auto h-full w-full bg-zinc-800">
    <Header
      title="Set Predictions"
      :sub-title="loading ? '' : activeLeague.name"
      :back-route="{ name: 'dashboard' }"
    />
    <div class="h-3/4 rounded-t-2xl bg-gray-100 text-center">
      <fa
        v-if="loading"
        icon="circle-notch"
        size="2x"
        class="mt-16 text-zinc-800"
        spin
      />
      <div v-if="!loading" class="h-full">
        <div class="flex justify-center bg-white pb-10 pt-14">
          <div class="w-1/2">
            <div class="flex justify-end">
              <TeamEmblem
                :team="active_fixture.home_team.data"
                position="right"
                text-classes="inline-block mr-2 whitespace-nowrap text-right text-lg font-semibold"
                image-classes="mr-3 inline w-8 self-start"
              />
            </div>
            <input
              v-model="prediction_h"
              ref="score_home"
              type="number"
              name="score_home"
              class="float-right mr-8 w-20 border-0 border-b-4 border-black text-center text-lg text-black placeholder-gray-400 focus:border-b-teal-400 focus:ring-0"
              placeholder="0"
              autocomplete="off"
              v-on:keyup.enter="setFocus('score_away')"
            />
          </div>
          <div class="w-1/2">
            <div class="flex flex-nowrap justify-start">
              <TeamEmblem
                :team="active_fixture.away_team.data"
                position="left"
                text-classes="inline-block ml-2 whitespace-nowrap text-left text-lg font-semibold"
                image-classes="ml-3 inline w-8 self-start"
              />
            </div>
            <input
              v-model="prediction_a"
              ref="score_away"
              type="number"
              name="score_away"
              class="float-left ml-8 w-20 border-0 border-b-4 border-black text-center text-lg text-black placeholder-gray-400 focus:border-b-teal-400 focus:ring-0"
              placeholder="0"
              autocomplete="off"
              v-on:keyup.enter="savePrediction"
            />
          </div>
        </div>
        <div class="w-full py-3 px-6">
          <div class="flex items-center justify-between">
            <span
              :class="['font-semibold', { 'opacity-20': !can_use_bonus_lock }]"
              >Enable Lock
              <fa
                icon="question-circle"
                class="ml-1 text-amber-600"
                @click="toggleBonusLockHelp"
              ></fa
            ></span>

            <Toggle
              :checked="bonus_lock_active"
              name="bonus_lock"
              v-model="bonus_lock_active"
              :disabled="!can_use_bonus_lock"
            />
          </div>
          <div
            v-if="show_bonus_lock_help"
            class="relative mt-2 rounded bg-amber-300 p-3 text-sm"
          >
            <fa
              icon="times"
              class="absolute right-1 top-1 text-amber-600"
              @click="toggleBonusLockHelp"
            ></fa>
            Avoid losing points if you guess the wrong score
          </div>
          <div class="mt-3 flex items-center justify-between">
            <span
              :class="['font-semibold', { 'opacity-20': !can_use_bonus_coin }]"
              >Enable Coin
              <fa
                icon="question-circle"
                class="ml-1 text-amber-600"
                @click="toggleBonusCoinHelp"
              ></fa>
            </span>
            <Toggle
              :checked="bonus_coin_active"
              name="bonus_coin"
              v-model="bonus_coin_active"
              :disabled="!can_use_bonus_coin"
            />
          </div>
          <div
            v-if="show_bonus_coin_help"
            class="relative mt-2 rounded bg-amber-300 p-3 text-sm"
          >
            <fa
              icon="times"
              class="absolute right-1 top-1 text-amber-600"
              @click="toggleBonusCoinHelp"
            ></fa>
            Gain x2 points for any points earned in this fixture
          </div>
          <div class="mt-4">
            <Button
              :text="saveButtonText"
              :disabled="!canSavePrediction"
              @click="savePrediction"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button"
import Header from "@/components/layout/Header.vue"
import Toggle from "@/components/Toggle.vue"
import TeamEmblem from "@/components/TeamEmblem.vue"
import RendersTeams from "@/mixins/RendersTeams"
import { store } from "@/stores/MainStore.js"
import { mapState } from "pinia"

export default {
  name: "SetPredictions",
  components: {
    Button,
    Header,
    Toggle,
    TeamEmblem,
  },
  mixins: [RendersTeams],
  props: {
    gameweek: {
      type: [String, Number],
      required: true,
    },
    explicit_fixture_id: {
      type: [String, Number],
    },
    can_use_bonus_coin_default: {
      type: Boolean,
      default: true,
    },
    can_use_bonus_lock_default: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.getSetPredictionData()
  },
  data() {
    return {
      loading: true,
      predictions: null,
      fixtures: null,
      active_fixture: null,
      prediction_h: null,
      prediction_a: null,
      bonus_coin_active: false,
      bonus_lock_active: false,
      can_use_bonus_coin: this.can_use_bonus_coin_default,
      can_use_bonus_lock: this.can_use_bonus_lock_default,
      show_bonus_lock_help: false,
      show_bonus_coin_help: false,
    }
  },
  computed: {
    ...mapState(store, ["activeLeague"]),
    canSavePrediction() {
      return (
        parseInt(this.prediction_h) > -1 && parseInt(this.prediction_a) > -1
      )
    },
    saveButtonText() {
      if (this.explicit_fixture_id) {
        return "Save"
      }
      if (!this.getNextFixture()) {
        return "Finish"
      }
      return "Next"
    },
  },
  watch: {
    prediction_h(newScore, oldScore) {
      if (oldScore === null && parseInt(newScore) > -1) {
        this.setFocus("score_away")
      }
    },
  },
  methods: {
    async getSetPredictionData() {
      const fixtureResp = await this.$api.getFixtures(this.gameweek, [
        "home_team",
        "away_team",
      ])
      const fixtureData = fixtureResp.data.data

      const predictionResp = await this.$api.getPredictions(
        this.gameweek,
        this.activeLeague.id
      )
      const predictionData = predictionResp.data.data

      this.predictions = predictionData
      this.fixtures = fixtureData

      const nextFixtureToSet = fixtureData.find(
        (fixture) =>
          !fixture.deadline_passed &&
          !predictionData
            .map((prediction) => prediction.fixture_id)
            .includes(fixture.id)
      )

      const activeFixture = this.explicit_fixture_id
        ? fixtureData.find((fixture) => fixture.id == this.explicit_fixture_id)
        : nextFixtureToSet

      this.setActiveFixture(activeFixture)

      this.loading = false

      this.setFocus("score_home")
    },
    setActiveFixture(activeFixture) {
      const prediction = this.predictions.find(
        (prediction) => prediction.fixture_id === activeFixture.id
      )

      this.prediction_h = null
      this.prediction_a = null
      this.bonus_coin_active = false
      this.bonus_lock_active = false

      if (prediction) {
        this.prediction_h = prediction.prediction_h
        this.prediction_a = prediction.prediction_a
        this.bonus_coin_active = prediction.bonus_coin_active
        this.bonus_lock_active = prediction.bonus_lock_active
      }

      this.active_fixture = activeFixture
    },
    savePrediction() {
      const {
        prediction_h,
        prediction_a,
        bonus_lock_active,
        bonus_coin_active,
      } = this

      this.$api
        .savePrediction(this.active_fixture.id, {
          prediction_h,
          prediction_a,
          bonus_lock_active,
          bonus_coin_active,
        })
        .then(() => {
          const newActiveFixture = this.getNextFixture()

          if (!this.explicit_fixture_id && newActiveFixture) {
            this.can_use_bonus_coin =
              !this.bonus_coin_active && this.can_use_bonus_coin
            this.can_use_bonus_lock =
              !this.bonus_lock_active && this.can_use_bonus_lock

            this.setActiveFixture(newActiveFixture)
            this.setFocus("score_home")
            return
          }

          this.$router.push({ name: "dashboard" })
        })
    },
    getNextFixture() {
      const activeFixtureKey = this.fixtures.findIndex(
        (fixture) => fixture.id === this.active_fixture.id
      )
      return this.fixtures[activeFixtureKey + 1]
    },
    setFocus(input) {
      this.$nextTick(() => this.$refs[input].focus())
    },
    toggleBonus(type) {
      const canUse = this[`can_use_bonus_${type}`]

      if (canUse) {
        const activeKey = `bonus_${type}_active`
        this[activeKey] = !this[activeKey]
      }
    },
    toggleBonusLockHelp() {
      this.show_bonus_lock_help = !this.show_bonus_lock_help
    },
    toggleBonusCoinHelp() {
      this.show_bonus_coin_help = !this.show_bonus_coin_help
    },
  },
}
</script>
