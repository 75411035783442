<template>
  <button
    :class="[
      'h-12',
      'w-full',
      'px-4',
      'font-semibold',
      'transition-colors',
      'duration-150',
      'rounded-md',
      'focus:shadow-outline',
      'border-b-2',
      { 'opacity-50': loading || disabled },
      ...variantStyles,
      ...classes,
    ]"
    :disabled="loading || disabled"
    @click="$emit('click')"
  >
    <fa v-if="loading" icon="circle-notch" spin class="mr-2" />
    {{ this.text }}
    <fa
      v-if="iconRight"
      :icon="iconRight"
      class="float-right mt-1 ml-2 inline-block"
    />
  </button>
</template>

<script>
export default {
  name: "Button",
  computed: {
    variantStyles() {
      if (this.variant === "primary") {
        return [
          "bg-primary-500",
          "hover:bg-primary-600",
          "border-primary-700",
          "text-white",
        ]
      }
      if (this.variant === "secondary") {
        return [
          "bg-slate-700",
          "hover:bg-slate-800",
          "hover:bg-slate-800",
          "border-slate-900",
          "text-white",
        ]
      }
      return []
    },
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    iconRight: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
}
</script>
