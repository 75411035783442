<template>
  <div class="mx-auto h-full w-full bg-slate-900" id="dashboard">
    <div
      class="fixed top-0 left-0 z-20 h-auto w-full overflow-auto bg-slate-900 pt-3 shadow-lg shadow-slate-900/50"
    >
      <div class="mb-4 ml-3 block text-white" @click="goBack">
        <fa icon="arrow-left" class="mr-2" />Back
      </div>
      <div class="flex items-center justify-between px-3">
        <div>
          <div class="text-lg font-semibold text-amber-400">
            <span>{{ this.user.username }}</span>
          </div>
          <div class="text-white">Gameweek {{ visibleGameweek }}</div>
        </div>
        <div>
          <div
            v-if="visibleGameweek > 1"
            @click="onGameweekSwitch('prev')"
            class="inline-block h-6 w-6 rounded-full bg-slate-700 text-center text-white"
          >
            <fa
              icon="chevron-left"
              class="relative -top-[2px] -left-[1px] cursor-pointer text-xs"
            />
          </div>
          <div
            v-if="visibleGameweek < activeGameweek"
            @click="onGameweekSwitch('next')"
            class="ml-3 inline-block h-6 w-6 rounded-full bg-slate-700 text-center text-white"
          >
            <fa
              icon="chevron-right"
              class="relative -top-[2px] left-[1px] cursor-pointer text-xs"
            />
          </div>
        </div>
      </div>
      <div
        class="relative mt-4 border-b border-t border-b-white/10 border-t-slate-900"
      ></div>
    </div>
    <div
      :class="[
        'flex',
        'justify-center',
        'relative',
        'z-10',
        'transition-all',
        'md:max-w-xl',
        'md:mx-auto',
        'pt-[130px]',
      ]"
    >
      <DashboardFigure
        :figure="loading ? '--' : gameweekPoints"
        text="GW Points"
      />
    </div>
    <div
      class="relative z-10 mt-3 min-h-full overflow-auto rounded-t-2xl bg-white py-2 text-center shadow"
    >
      <fa
        v-if="loading"
        icon="circle-notch"
        size="2x"
        class="mt-16 text-slate-900"
        spin
      />
      <DashboardFixtures
        v-if="!loading"
        :fixtures="fixtures"
        :predictions="predictions"
        :gameweek="visibleGameweek"
        observe
      />
    </div>
  </div>
</template>

<script>
import RendersTeams from "@/mixins/RendersTeams"
import DashboardFigure from "@/components/DashboardFigure.vue"
import DashboardFixtures from "@/components/DashboardFixtures.vue"
import { mapState, mapActions } from "pinia"
import { store } from "@/stores/MainStore.js"
import { sumBy } from "lodash"

export default {
  name: "UserPredictions",
  components: {
    DashboardFigure,
    DashboardFixtures,
  },
  mixins: [RendersTeams],
  created() {
    this.getPredictionData()
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(store, [
      "config",
      "visibleGameweek",
      "activeLeague",
      "activeGameweek",
    ]),
    gameweekPoints() {
      return sumBy(this.predictions, "points")
    },
  },
  data() {
    return {
      loading: true,
      fixtures: [],
      predictions: [],
      leaguePosition: null,
    }
  },
  methods: {
    ...mapActions(store, ["setVisibleGameweek"]),

    async getPredictionData() {
      const gameweek = this.visibleGameweek

      const fixtureResp = await this.$api.getFixtures(gameweek, [
        "home_team",
        "away_team",
      ])
      const fixtureData = fixtureResp.data.data

      const predictionResp = await this.$api.getPredictions(
        gameweek,
        this.activeLeague.id,
        this.user.id
      )
      const predictionData = predictionResp.data.data

      this.predictions = predictionData
      this.loading = false
      this.fixtures = fixtureData
    },

    onGameweekSwitch(direction) {
      this.loading = true
      const visibleGameweek =
        direction === "next"
          ? this.visibleGameweek + 1
          : this.visibleGameweek - 1

      this.setVisibleGameweek(visibleGameweek)
      this.getPredictionData()
    },
    goBack() {
      this.setVisibleGameweek(this.activeGameweek)
      this.$router.push({ name: "scoreboard" })
    },
  },
}
</script>
