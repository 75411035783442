<template>
  <div class="mx-auto h-full w-full" v-if="pageReady">
    <div
      class="min-h-full bg-cover pt-6 pb-10"
      :style="`background-image: url('${require('@/assets/images/home-bg.jpg')}')`"
    >
      <div
        class="mx-auto mb-14 w-1/2 text-center text-2xl font-bold text-white"
      >
        Matchday Predictor
      </div>
      <div class="mx-auto mb-6 px-4 text-center text-white" v-if="league">
        <p class="mb-4 text-xl">
          Join <strong>{{ league.name }}</strong> league
        </p>
        <img
          v-if="league.logo_url"
          :src="league.logo_url"
          class="mx-auto mb-10 w-1/2"
        />
        <p>
          Enter a username to join the
          <strong>{{ league.name }}</strong> Matchday Predictor league.<br />
        </p>
      </div>

      <div class="mx-6 mb-16 flex">
        <text-input
          name="username"
          :classes="['w-full', 'bg-white']"
          :error-classes="['!text-white', 'font-semibold']"
          :error="getError('username')"
          placeholder="Eg, Crouch Potato..."
          v-model="username"
        />
        <Button
          text="Join"
          :disabled="!canSubmit || submitting"
          :classes="['w-3/5 ml-3', 'border-none']"
          @click="onSubmit"
          icon-right="arrow-right"
        />
      </div>

      <div class="px-6">
        <div
          class="mb-4 flex items-center justify-between border-t border-b border-white/50 py-4 text-lg font-bold text-white"
          @click="() => (showHelp = !showHelp)"
        >
          What is Matchday Predictor?
          <fa :icon="showHelp ? 'chevron-down' : 'chevron-right'" />
        </div>
        <div :class="['text-white', 'help-panel', showHelp ? 'open' : '']">
          <p class="mb-3">
            Matchday Predictor is a free, competitive football predictor game to
            play with your mates. Predict the football scores each week to climb
            up the leaderbord and claim the glory!
          </p>
          <p>Simply enter your username above to get going.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue"
import Button from "@/components/Button.vue"
import HandlesFormRequests from "@/mixins/HandlesFormRequests"
import HandlesJoinLeague from "@/mixins/HandlesJoinLeague"
import ConfiguresApp from "@/mixins/ConfiguresApp"
import _ from "lodash"
import { store } from "@/stores/MainStore.js"
import { mapState, mapActions } from "pinia"
import { REGISTER_CONTEXT_JOIN } from "@/constants/app"
import track from "@/tracking"

export default {
  name: "Register",
  components: {
    Button,
    TextInput,
  },
  mixins: [HandlesFormRequests, HandlesJoinLeague, ConfiguresApp],
  mounted() {
    this.getData()
  },
  computed: {
    ...mapState(store, ["user", "source"]),
    canSubmit() {
      return !_.isEmpty(this.username)
    },
  },
  props: {
    league_code: {
      type: String,
      required: false,
    },
    league_slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      username: "",
      submitting: false,
      pageReady: false,
      league: null,
      showHelp: false,
    }
  },
  methods: {
    ...mapActions(store, ["setOngoingLeagueJoin"]),
    async getData() {
      const league = await this.$api
        .checkLeague({
          code: this.league_code,
          slug: this.league_slug,
        })
        .catch((error) => {
          if (error.response.status !== 200) {
            this.$router.push({ name: "home" })
          }
        })

      if (league) {
        this.league = league.data.data

        track("Join page visited", {
          source: this.source,
          league: this.league.name,
        })

        if (this.user) {
          const leagues = await this.$api.getLeagues()
          if (
            leagues.data.data.find((league) => league.code === this.league.code)
          ) {
            return this.$router.push({ name: "dashboard" })
          }
        }
        this.pageReady = true
      }
    },
    onSubmit() {
      this.submitting = true

      this.setOngoingLeagueJoin(
        this.league.id,
        this.league.name,
        this.username
      )

      if (!this.user) {
        this.$router.push({
          name: "register",
          params: { context: REGISTER_CONTEXT_JOIN },
        })
        return
      }

      this.joinLeague().catch((response) => {
        this.handleErrorResponse(response)
        this.submitting = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.help-panel {
  overflow: auto;
  max-height: 0;
  transition: max-height 0.2s ease-out;

  &.open {
    max-height: 1000px;
  }
}
</style>
