<template>
  <div class="mx-auto h-full w-full" v-if="!loading">
    <div
      class="h-2/3 bg-cover pt-6"
      :style="`background-image: url('${require('@/assets/images/home-bg.jpg')}')`"
    >
      <div
        class="mx-auto mb-14 w-1/2 text-center text-2xl font-bold text-white"
      >
        Matchday Predictor
      </div>
      <div class="mx-auto mb-20 px-4 text-center text-white">
        <strong>The ultimate football predictor game.</strong><br />Join or
        create a league. Predict the correct scores each week. Claim the glory!
      </div>
      <div class="mx-6 flex">
        <text-input
          name="league"
          :classes="['w-full', 'bg-white']"
          :error-classes="['!text-white', 'font-semibold']"
          :error="invalidLeagueCode ? 'Unable to locate league' : null"
          placeholder="League code..."
          v-model="leagueCode"
        />
        <Button
          text="Join"
          @click="onJoinLeague"
          :disabled="joinLeagueLoading"
          :classes="['w-2/5 ml-3', 'border-none']"
          icon-right="arrow-right"
        />
      </div>
    </div>
    <router-link
      v-if="user"
      :to="{ name: 'dashboard' }"
      class="absolute top-0 right-0 m-3 h-8 w-8 rounded-full bg-primary-500 text-center font-semibold text-white"
      ><fa icon="users" class="mt-2"
    /></router-link>
    <div
      class="relative -top-[14px] z-20 w-full rounded-t-2xl bg-white p-6 pt-8"
    >
      <div class="mb-6 sm:mx-auto sm:w-2/3 sm:pt-2 lg:w-1/2">
        <Button
          text="Create a new league"
          variant="secondary"
          :class="['mb-3']"
          @click="$router.push({ name: 'create-league' })"
        />
        <router-link
          :to="{ name: 'login' }"
          class="block text-center underline"
          v-if="!this.user"
          >Sign in</router-link
        >
      </div>
      <HelpAccordian />
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue"
import Button from "@/components/Button.vue"
import HelpAccordian from "@/components/HelpAccordian.vue"
import { store } from "@/stores/MainStore.js"
import { mapState } from "pinia"
import { getUrlParam } from "@/helpers/url"

export default {
  name: "Home",
  components: {
    Button,
    TextInput,
    HelpAccordian,
  },
  data() {
    return {
      joinLeagueLoading: false,
      leagueCode: "",
      invalidLeagueCode: false,
      loading: true,
    }
  },
  mounted() {
    const skipRedirect = getUrlParam("skip_redirect")

    if (this.user !== null && !skipRedirect) {
      this.$router.push({ name: "dashboard" })
    } else {
      this.loading = false
    }
  },
  watch: {
    leagueCode() {
      this.invalidLeagueCode = false
    },
  },
  computed: {
    ...mapState(store, ["user"]),
  },
  methods: {
    onJoinLeague() {
      this.joinLeagueLoading = true

      this.$api
        .checkLeague({
          code: this.leagueCode,
        })
        .then(({ data }) => {
          const { slug } = data.data

          this.$router.push({
            name: "join",
            params: {
              league_slug: slug,
            },
          })
        })
        .catch(() => {
          this.invalidLeagueCode = true
        })
        .finally(() => (this.joinLeagueLoading = false))
    },
  },
}
</script>
