import { store } from "@/stores/MainStore.js"
import { mapActions, mapState } from "pinia"
import track from "@/tracking"

export default {
  computed: {
    ...mapState(store, ["ongoingLeagueJoin", "source"]),
  },
  methods: {
    ...mapActions(store, ["setUser"]),

    joinLeague() {
      return this.$api
        .joinLeague(this.ongoingLeagueJoin.leagueId, {
          username: this.ongoingLeagueJoin.username,
        })
        .then(() => {
          this.configureApp(() => {
            track("User joined league", {
              source: this.source,
              league: this.ongoingLeagueJoin.leagueName,
              username: this.ongoingLeagueJoin.username,
            })
            this.$router.push({ name: "dashboard" })
          })
        })
    },
  },
}
