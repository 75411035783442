<template>
  <div class="mx-auto h-full w-full bg-zinc-800">
    <Header
      :title="titleText"
      :sub-title="helpText"
    />
    <div class="h-3/4 rounded-t-2xl bg-white p-6">
      <div class="sm:mx-auto sm:w-2/3 sm:pt-2 lg:w-1/2">
        <form autocomplete="off" id="register_form">
          <text-input
            name="email"
            placeholder="bob@example.com"
            :classes="['w-full']"
            :error="getError('email')"
            v-model="email"
            label="Email address"
          />
          <text-input
            name="name"
            placeholder="Bob"
            :classes="['w-full']"
            :error="getError('name')"
            v-model="name"
            label="First Name"
          />
          <text-input
            name="password"
            placeholder="password"
            :classes="['w-full']"
            :error="getError('password')"
            v-model="password"
            type="password"
            label="Password"
          />
        </form>
        <Button
          text="Continue"
          :classes="['mt-5 mb-4']"
          :disabled="!canSubmit"
          :loading="loading"
          @click="onSubmit"
        />
        <router-link
          :to="{
            name: 'login',
            params: {
              context: loginContext,
            },
          }"
          class="block text-center text-link-800 underline"
          >Already have an account?</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue"
import Button from "@/components/Button.vue"
import Header from "@/components/layout/Header.vue"
import HandlesFormRequests from "@/mixins/HandlesFormRequests"
import HandlesJoinLeague from "@/mixins/HandlesJoinLeague"
import ConfiguresApp from "@/mixins/ConfiguresApp"
import HandlesLogin from "@/mixins/HandlesLogin"
import { isEmpty } from "lodash"
import {
  REGISTER_CONTEXT_JOIN,
  LOGIN_CONTEXT_JOIN,
  LOGIN_CONTEXT_CREATE,
  REGISTER_CONTEXT_CREATE,
} from "@/constants/app"
import { store } from "@/stores/MainStore.js"
import { mapState } from "pinia"

export default {
  name: "Register",
  components: {
    Button,
    TextInput,
    Header,
  },
  mixins: [HandlesFormRequests, HandlesLogin, HandlesJoinLeague, ConfiguresApp],
  computed: {
    ...mapState(store, ["ongoingLeagueJoin"]),
    canSubmit() {
      return !isEmpty(this.email) && !isEmpty(this.password)
    },
    titleText() {
      return 'Nearly there...'
    },
    helpText() {
      if (this.context === REGISTER_CONTEXT_JOIN) {
        return "Create an account to join this league"
      }
      return "Create an account to create your league"
    },
    loginContext() {
      return this.context === REGISTER_CONTEXT_JOIN
        ? LOGIN_CONTEXT_JOIN
        : LOGIN_CONTEXT_CREATE
    },
  },
  props: {
    context: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: "",
      name: "",
      password: "",
      loading: false,
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      this.resetErrors()

      await this.$api
        .register({
          email: this.email,
          name: this.name,
          password: this.password,
          password_confirmation: this.password,
        })
        .catch((response) => {
          this.handleErrorResponse(response)
          this.loading = false
        })

      if (!isEmpty(this.errors)) {
        return
      }

      await this.login(this.email, this.password)

      if (this.context === REGISTER_CONTEXT_JOIN) {
        this.joinLeague()
      }

      if (this.context === REGISTER_CONTEXT_CREATE) {
        this.$router.push({ name: "create-league" })
      }
    },
  },
}
</script>
