var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'h-12',
    'w-full',
    'px-4',
    'font-semibold',
    'transition-colors',
    'duration-150',
    'rounded-md',
    'focus:shadow-outline',
    'border-b-2',
    { 'opacity-50': _vm.loading || _vm.disabled } ].concat( _vm.variantStyles,
    _vm.classes ),attrs:{"disabled":_vm.loading || _vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.loading)?_c('fa',{staticClass:"mr-2",attrs:{"icon":"circle-notch","spin":""}}):_vm._e(),_vm._v(" "+_vm._s(this.text)+" "),(_vm.iconRight)?_c('fa',{staticClass:"float-right mt-1 ml-2 inline-block",attrs:{"icon":_vm.iconRight}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }