<template>
  <div class="flex h-1/4 w-full flex-col justify-center overflow-auto px-3">
    <router-link
      v-if="backRoute"
      :to="backRoute"
      class="absolute top-3 justify-self-start text-sm text-white"
    >
      <fa icon="arrow-left" class="mr-2" />Back
    </router-link>
    <div class="text-center">
      <div class="text-xl font-semibold text-white">{{ title }}</div>
      <div class="mt-2 text-white/75" v-if="subTitle">{{ subTitle }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
    },
    backRoute: {
      type: Object,
    },
  },
}
</script>
