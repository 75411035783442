import _ from "lodash"

export default {
  data() {
    return {
      errors: {},
    }
  },
  methods: {
    handleErrorResponse({ response }) {
      if (response.status === 422) {
        const errors = response.data.errors
        Object.keys(errors).forEach((field) => {
          this.$set(this.errors, field, errors[field][0])
        })
      }
    },
    getError(field) {
      return _.get(this.errors, field, null)
    },
    resetErrors() {
      this.errors = {}
    },
  },
}
