<template>
  <div class="flex items-center justify-between px-3">
    <div>
      <div class="text-lg font-semibold text-white">
        Gameweek {{ visibleGameweek }}
      </div>
      <div class="text-white/75">
        <span class="underline" @click="$emit('toggleLeaguePanel')">{{
          this.activeLeague.name
        }}</span>
        <fa
          :icon="panelOpen ? 'chevron-up' : 'chevron-down'"
          class="relative top-[2px] ml-2 text-sm"
        />
      </div>
    </div>
    <div>
      <div
        v-if="visibleGameweek > 1"
        @click="$emit('onGameweekSwitch', 'prev')"
        class="inline-block h-6 w-6 rounded-full bg-primary-500 text-center"
      >
        <fa
          icon="chevron-left"
          class="relative -top-[2px] -left-[1px] cursor-pointer text-xs text-white"
        />
      </div>
      <div
        v-if="visibleGameweek < config.total_gameweeks"
        @click="$emit('onGameweekSwitch', 'next')"
        class="ml-3 inline-block h-6 w-6 rounded-full bg-primary-500 text-center"
      >
        <fa
          icon="chevron-right"
          class="relative -top-[2px] left-[1px] cursor-pointer text-xs text-white"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/stores/MainStore.js"
import { mapState } from "pinia"

export default {
  computed: {
    ...mapState(store, ["config"]),
  },
  props: {
    visibleGameweek: {
      type: Number,
    },
    activeLeague: {
      type: Object,
    },
    fixtures: {
      type: Array,
    },
    panelOpen: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" scoped></style>
