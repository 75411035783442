<template>
  <div>
    <div
      :key="fixture.id"
      v-for="fixture in fixtures"
      @click="() => onFixtureClick(fixture)"
      class="relative border-b border-gray-200 px-2 pt-3 pb-4 text-sm last:mb-8 last:border-none"
    >
      <div class="mb-1 text-xs font-semibold text-gray-400">
        {{ formatTime(fixture.kickoff_at) }}
      </div>
      <div class="mb-3 flex items-center justify-between">
        <div
          class="flex w-1/2 items-center justify-end text-right font-semibold"
        >
          <TeamEmblem :team="fixture.home_team.data" position="right" />
        </div>
        <div class="mx-6 font-semibold">
          <div class="mb-1">
            <span>{{ fixture.score_h !== null ? fixture.score_h : "-" }}</span>
            <span class="mx-1">:</span>
            <span>{{ fixture.score_a !== null ? fixture.score_a : "-" }}</span>
          </div>
        </div>
        <div class="flex w-1/2 items-center text-left font-semibold">
          <TeamEmblem :team="fixture.away_team.data" position="left" />
        </div>
      </div>
      <div
        :class="[
          'text-xs',
          'inline-block',
          'mx-auto',
          'min-w-[40px]',
          'p-1',
          'uppercase',
          'font-semibold',
          'text-white',
          'bg-blue-400',
          'border',
          'border-white',
        ]"
        v-if="predictionSetForFixture(fixture)"
      >
        {{ getPredictionText(fixture) }}
      </div>
      <div
        :class="[
          'text-xs',
          'inline-block',
          'mx-auto',
          'p-1',
          'min-w-[30px]',
          'uppercase',
          'font-semibold',
          'text-white',
          'bg-red-400',
          'border',
          'border-white',
        ]"
        v-if="getPredictionForFixture(fixture).bonus_lock_active"
      >
        <fa icon="lock" />
      </div>
      <div
        :class="[
          'text-xs',
          'inline-block',
          'mx-auto',
          'p-1',
          'min-w-[30px]',
          'uppercase',
          'font-semibold',
          'text-white',
          'bg-amber-500',
          'border',
          'border-white',
        ]"
        v-if="getPredictionForFixture(fixture).bonus_coin_active"
      >
        <fa icon="coins" />
      </div>
      <div
        :class="[
          'text-xs',
          'inline-block',
          'mx-auto',
          'min-w-[40px]',
          'p-1',
          'uppercase',
          'font-semibold',
          'text-white',
          'border',
          'border-white',
          getBackgroundColorForPointsPill(fixture),
        ]"
        v-if="hasPointsForFixture(fixture)"
      >
        {{ getPointsText(fixture) }}
      </div>
      <div
        :class="[
          'text-xs',
          'inline-block',
          'mx-auto',
          'py-1',
          'px-2',
          'uppercase',
          'font-semibold',
          'text-white',
          'border',
          'border-white',
          'bg-primary-500',
        ]"
        v-if="canSetPrediction(fixture)"
      >
        {{ hasSetPrediction(fixture) ? "Update" : "Set" }}
        <fa icon="arrow-right" />
      </div>
      <div
        v-if="fixture.is_postponed"
        class="mt-2 rounded bg-rose-500 py-[2px] text-xs text-white"
      >
        <fa icon="exclamation-circle" class="mr-2" />
        This game may be postponed
      </div>
    </div>
  </div>
</template>

<script>
import TeamEmblem from "@/components/TeamEmblem.vue"
import moment from "moment"
import { mapState } from "pinia"
import { store } from "@/stores/MainStore.js"

export default {
  components: {
    TeamEmblem,
  },
  props: {
    fixtures: {
      type: Array,
    },
    predictions: {
      type: Array,
    },
    gameweek: {
      type: Number,
    },
    can_use_bonus_coin: {
      type: Boolean,
    },
    can_use_bonus_lock: {
      type: Boolean,
    },
    observe: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(store, ["activeGameweek"]),
  },
  methods: {
    getPredictionText(fixture) {
      const prediction = this.getPredictionForFixture(fixture, null)

      if (!fixture.finished && this.observe) {
        return
      }

      return `${prediction.prediction_h} : ${prediction.prediction_a}`
    },
    getPointsText(fixture) {
      const prediction = this.getPredictionForFixture(fixture, null)
      return prediction.points
    },
    canSetPrediction(fixture) {
      if (this.observe) {
        return false
      }

      if (fixture.deadline_passed) {
        return false
      }

      if (
        this.activeGameweek === fixture.gameweek ||
        fixture.gameweek === this.activeGameweek + 1
      ) {
        return true
      }

      return false
    },
    hasSetPrediction(fixture) {
      const prediction = this.getPredictionForFixture(fixture, null)
      return prediction.prediction_h !== null
    },
    getPredictionForFixture(fixture, defaultVal) {
      const prediction = this.predictions.find(
        (prediction) => prediction.fixture_id === fixture.id
      )

      if (!prediction) {
        return {
          prediction_h: defaultVal,
          prediction_a: defaultVal,
          bonus_coin_active: defaultVal,
          bonus_lock_active: defaultVal,
          points: defaultVal,
        }
      }

      return {
        prediction_h: prediction.prediction_h,
        prediction_a: prediction.prediction_a,
        bonus_coin_active: prediction.bonus_coin_active,
        bonus_lock_active: prediction.bonus_lock_active,
        points: prediction.points,
      }
    },
    predictionSetForFixture(fixture) {
      return this.getPredictionForFixture(fixture, null).prediction_h !== null
    },
    hasPointsForFixture(fixture) {
      return this.getPredictionForFixture(fixture, null).points !== null
    },
    getBackgroundColorForPointsPill(fixture) {
      const points = this.getPredictionForFixture(fixture, null).points

      if (points < 0) {
        return "bg-red-400"
      }

      if (points === 0) {
        return "bg-gray-400"
      }

      if (points > 0) {
        return "bg-primary-500"
      }
    },
    onFixtureClick(fixture) {
      if (!this.canSetPrediction(fixture)) {
        return
      }

      const canUseBonusCoin = this.predictions.find(
        (prediction) =>
          prediction.bonus_coin_active && prediction.fixture_id !== fixture.id
      )
        ? false
        : true

      const canUseBonusLock = this.predictions.find(
        (prediction) =>
          prediction.bonus_lock_active && prediction.fixture_id !== fixture.id
      )
        ? false
        : true

      this.$router.push({
        name: "set-predictions",
        params: {
          gameweek: this.gameweek,
          explicit_fixture_id: fixture.id,
          can_use_bonus_coin_default: canUseBonusCoin,
          can_use_bonus_lock_default: canUseBonusLock,
        },
      })
    },
    formatTime(date) {
      return moment(date).calendar(moment(), {
        lastDay: "[Yesterday –] HH:mm",
        sameDay: "[Today –] HH:mm",
        nextDay: "[Tomorrow –] HH:mm",
        lastWeek: "DD/MM/YYYY – HH:mm",
        nextWeek: "DD/MM/YYYY – HH:mm",
        sameElse: "DD/MM/YYYY – HH:mm",
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
