import { store } from "@/stores/MainStore.js"
import { mapActions } from "pinia"

export default {
  methods: {
    ...mapActions(store, ["setUser"]),

    async login(email, password, cb) {
      return this.$api
        .login({
          email: email,
          password: password,
        })
        .then(() => {
          return this.$api
            .getUser()
            .then(({ data }) => {
              const user = data.data
              this.setUser(user)
              cb()
            })
            .catch(() => {})
        })
    },
  },
}
