<template>
  <div class="mx-auto h-full w-full">
    <div
      class="h-full bg-cover pt-6"
      :style="`background-image: url('${require('@/assets/images/home-bg.jpg')}')`"
    >
      <div class="mx-auto w-1/2 text-center text-2xl font-bold text-white">
        Matchday Predictor
      </div>
      <div class="mx-auto h-full absolute top-0 px-4 text-center align-middle items-center flex text-white">
        <div>
          <p class="mb-6 text-lg">
            We're warming up for the new season! The first game is scheduled for<br />
            <strong>5th August</strong>.
          </p>
          <p>Please check back soon to start setting your predictions.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WaitingForSeason",
  data() {
    return {
      joinLeagueLoading: false,
      leagueCode: "",
      invalidLeagueCode: false,
    }
  },
}
</script>
