import { ACTIVE_LEAGUE_STORAGE_KEY } from "@/constants/app.js"
import { get } from "lodash"
import { store } from "@/stores/MainStore.js"
import { mapActions } from "pinia"
import { getUrlParam } from "@/helpers/url"
import * as PusherPushNotifications from "@pusher/push-notifications-web"
import Cookies from "js-cookie"
import { isPushApiSupported } from "@/helpers/push"

export default {
  methods: {
    ...mapActions(store, [
      "setUser",
      "setActiveLeague",
      "setActiveGameweek",
      "setVisibleGameweek",
      "setConfig",
      "setSource",
    ]),

    async configureApp(cb) {
      await this.$api.setCSFR()

      this.setTrackingSource()

      const user = await this.$api
        .getUser(["leagues", "leagues.players"])
        .catch(() => {
          this.redirectIfNotAuthenticated()
          cb()
        })

      if (user === undefined) {
        return
      }

      const config = await this.getConfig()

      const activeGameweek = await this.getActiveGameweek()

      const activeLeague = this.locateActiveLeague(user.data.data.leagues.data)

      if (activeLeague) {
        this.saveActiveLeagueToStorage(activeLeague)
      }

      this.setConfig(config.data.data)
      this.setUser(user.data.data)
      this.setActiveLeague(activeLeague)
      this.setActiveGameweek(activeGameweek.data.data.active_gameweek)
      this.setVisibleGameweek(activeGameweek.data.data.active_gameweek)
      this.configureRouter(user.data.data)

      if (isPushApiSupported()) {
        this.configurePush(user.data.data)
      }

      cb()
    },
    redirectIfNotAuthenticated() {
      if (
        this.$route.name !== "login" &&
        get(this.$route.meta, "secure", false)
      ) {
        this.$router.push({ name: "login" })
      }
    },
    async getActiveGameweek() {
      return this.$api.getActiveGameweek()
    },
    async getConfig() {
      return this.$api.getConfig()
    },
    locateActiveLeague(leagues) {
      const activeLeagueID = localStorage.getItem(
        ACTIVE_LEAGUE_STORAGE_KEY,
        null
      )

      let activeLeague = null

      if (activeLeagueID) {
        activeLeague = leagues.find(
          (league) => league.id === parseInt(activeLeagueID)
        )
      }

      if (!activeLeague) {
        activeLeague = leagues[0]
      }

      return activeLeague
    },
    saveActiveLeagueToStorage(league) {
      localStorage.setItem(ACTIVE_LEAGUE_STORAGE_KEY, league.id)
    },
    configureRouter(user) {
      // Configure router to redirect to login / dashboard based on auth rules
      this.$router.beforeEach((to, from, next) => {
        if (get(to.meta, "secure", false) && user === null) {
          next({ name: "login" })
        }
        if (get(to.meta, "auth_redirect", false) && user !== null) {
          next({ name: "dashboard" })
        }
        next()
      })

      if (
        user === null &&
        get(this.$route.meta, "secure", false) &&
        this.$route.name !== "login"
      ) {
        this.$router.push({ name: "login" })
      }

      if (
        user !== null &&
        get(this.$route.meta, "auth_redirect", false) &&
        this.$route.name !== "dashboard"
      ) {
        this.$router.push({ name: "dashboard" })
      }
    },
    setTrackingSource() {
      const source = getUrlParam("source")

      if (source) {
        this.setSource(source)
      }
    },
    configurePush(user) {
      const pushKey = `user.${user.id}`

      const cookie = Cookies.get("predictor_push_configured")

      if (!cookie) {
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: process.env.VUE_APP_PUSHER_INSTANCE_ID,
        })

        beamsClient
          .start()
          .then(() => beamsClient.getDeviceInterests())
          .then((interests) => {
            if (!interests.includes(pushKey)) {
              beamsClient.addDeviceInterest(pushKey)
            }
            Cookies.set("predictor_push_configured", true)
          })
          .catch((e) => console.log(e))
      }
    },
  },
}
