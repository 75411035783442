<template>
  <div>
    <span
      v-if="showTeamName && position === 'right'"
      :class="textClasses ? textClasses : 'mr-2'"
      >{{ team.name }}</span
    >
    <img :src="getTeamLogoSrc(team.short_name)" :class="imageClasses" />
    <span
      v-if="showTeamName && position === 'left'"
      :class="textClasses ? textClasses : 'ml-2'"
      >{{ team.name }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    team: {
      type: Object,
    },
    position: {
      type: String,
      default: "left",
    },
    textClasses: {
      type: String,
      default: "",
    },
    imageClasses: {
      type: String,
      default: "inline w-5",
    },
    showTeamName: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getTeamLogoSrc(shortName) {
      return require(`@/assets/images/teams/${shortName.toLowerCase()}.png`)
    },
  },
}
</script>

<style lang="scss" scoped></style>
