<template>
  <div class="mx-auto h-full w-full bg-zinc-800" v-if="mounted">
    <Header title="Create League" />
    <div class="h-3/4 rounded-t-2xl bg-white p-6">
      <div class="sm:mx-auto sm:w-2/3 sm:pt-2 lg:w-1/2">
        <div v-if="!created">
          <form autocomplete="off" id="create_league_form">
            <div class="mb-3">
              <text-input
                name="name"
                variant="light"
                label="League Name"
                placeholder="Eg, Manchester Blues..."
                :classes="['w-full']"
                :error="getError('name')"
                v-model="name"
              />
            </div>
            <div class="mb-6">
              <text-input
                name="username"
                variant="light"
                label="Your Username"
                placeholder="Eg, Show Me The Mane"
                :classes="['w-full']"
                :error="getError('username')"
                v-model="username"
              />
            </div>
          </form>
          <Button
            text="Create"
            :classes="['mt-4 mb-4']"
            :disabled="!canSubmit"
            :loading="loading"
            @click="onSubmit"
            icon-right="arrow-right"
          />
        </div>
        <div v-else>
          <div class="text-center">
            <p class="mb-5 text-center text-xl font-semibold">
              League Created!
            </p>
            <p class="mb-3">Players can join your league using the code:</p>
            <div
              class="mx-auto mb-8 inline-block rounded bg-slate-200 py-2 px-6 text-xl font-semibold"
            >
              {{ league.code }}
            </div>
            <Button
              text="Go to dashboard"
              @click="$router.push({ name: 'dashboard' })"
              icon-right="arrow-right"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue"
import Button from "@/components/Button.vue"
import HandlesFormRequests from "@/mixins/HandlesFormRequests"
import ConfiguresApp from "@/mixins/ConfiguresApp"
import Header from "@/components/layout/Header.vue"
import _ from "lodash"
import { mapState, mapActions } from "pinia"
import { store } from "@/stores/MainStore.js"
import { REGISTER_CONTEXT_CREATE } from "@/constants/app"

export default {
  name: "CreateLeague",
  components: {
    Button,
    TextInput,
    Header,
  },
  mixins: [HandlesFormRequests, ConfiguresApp],
  mounted() {
    if (!this.user) {
      this.$router.push({
        name: "register",
        params: { context: REGISTER_CONTEXT_CREATE },
      })
      return
    }
    this.mounted = true
  },
  computed: {
    ...mapState(store, ["user"]),
    canSubmit() {
      return !this.loading && !_.isEmpty(this.name)
    },
  },
  props: {},
  data() {
    return {
      name: "",
      username: "",
      mounted: false,
      loading: false,
      created: false,
      league: null,
    }
  },
  methods: {
    ...mapActions(store, ["setActiveLeague"]),
    onSubmit() {
      this.loading = true
      this.resetErrors()
      this.$api
        .createLeague({ name: this.name, username: this.username })
        .then(({ data }) => {
          this.loading = false
          this.created = true
          this.league = data.data
          this.configureApp(() => {})
        })
        .catch((response) => {
          this.handleErrorResponse(response)
          this.loading = false
        })
    },
  },
}
</script>
