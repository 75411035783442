/* eslint-disable no-console */

import { register } from "register-service-worker"
import * as PusherPushNotifications from "@pusher/push-notifications-web"
import { isPushApiSupported } from "@/helpers/push"

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration) {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      )

      if (isPushApiSupported()) {
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: process.env.VUE_APP_PUSHER_INSTANCE_ID,
          serviceWorkerRegistration: registration,
        })
        beamsClient
          .start()
          .then(() => beamsClient.getDeviceInterests())
          .then((interests) => console.log("Current interests:", interests))
      }
    },
    registered() {
      console.log("Service worker has been registered.")
    },
    cached() {
      console.log("Content has been cached for offline use.")
    },
    updatefound() {
      console.log("New content is downloading.")
    },
    updated() {
      console.log("New content is available; please refresh.")
      // window.location.reload(true)
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      )
    },
    error(error) {
      console.error("Error during service worker registration:", error)
    },
  })
}
