var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[(_vm.label)?_c('div',{class:[
      'text-xs',
      'mb-1',
      'font-semibold',
      'uppercase',
      _vm.variant === 'light' ? 'text-gray-900' : 'text-white',
      _vm.error
        ? _vm.variant === 'light'
          ? 'text-rose-500'
          : 'text-yellow-400'
        : _vm.variant === 'light'
        ? 'text-zinc-800'
        : 'text-white' ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('input',{class:[
      'block',
      'p-2 py-3',
      'rounded-md',
      'focus:border-white-400',
      'focus:ring-slate-400',
      'focus:ring',
      'focus:ring-opacity-50',
      'placeholder:text-sm',
      'placeholder:text-gray-400',
      'bg-gray-200',
      _vm.error
        ? _vm.variant === 'light'
          ? 'border-rose-500'
          : 'border-yellow-400'
        : _vm.variant === 'light'
        ? 'border-0'
        : 'border-white' ].concat( _vm.classes ),attrs:{"autocomplete":"off","type":_vm.type,"name":_vm.name,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}),(_vm.helpText)?_c('small',{staticClass:"text-gray-400"},[_vm._v(_vm._s(_vm.helpText))]):_vm._e(),_c('div',{class:[
      _vm.variant === 'light' ? 'text-rose-500' : 'text-yellow-400',
      'text-sm',
      'h-4',
      'mb-4' ].concat( _vm.errorClasses )},[(_vm.error)?_c('div',[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }