<template>
  <fa
    icon="circle-notch"
    :size="size"
    class="mx-auto mt-6 text-primary-500"
    spin
  />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "",
    },
  },
}
</script>
