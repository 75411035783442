<template>
  <div class="container mx-auto h-full w-full bg-zinc-800">
    <Header
      title="Leaderboard"
      :sub-title="league.name"
      :back-route="{ name: 'dashboard' }"
    />
    <div class="h-3/4 rounded-t-2xl bg-white pt-1 text-center">
      <fa
        v-if="loading"
        icon="circle-notch"
        size="2x"
        class="mx-auto mt-16 text-zinc-800"
        spin
      />
      <div v-if="!loading" class="pb-1">
        <div
          v-for="player in sortedPlayers"
          @click="goToUserPredictions(player)"
          :key="player.id"
          :class="[
            'flex',
            'items-center',
            'last:mb-8',
            'pt-3',
            'pb-4',
            'px-4',
            'm-3',
            'rounded-2xl',
            'relative',
            player.id === user.id
              ? 'bg-gradient-to-r from-teal-500 to-teal-400'
              : 'bg-gray-200',
            player.id === user.id ? 'text-white' : 'text-black',
          ]"
        >
          <span class="mr-6 flex items-center font-bold">
            <span class="mr-2 text-sm">
              <fa
                v-if="player.movement === 'up'"
                class="text-green-600"
                icon="chevron-up"
              />
              <fa
                v-if="player.movement === 'down'"
                class="text-red-600"
                icon="chevron-down"
              />
              <span
                v-if="player.movement === null"
                :class="[
                  'rounded-full',
                  'w-2',
                  'h-2',
                  'inline-block',
                  player.id === user.id ? 'bg-white' : 'bg-gray-300',
                ]"
              ></span>
            </span>
            <span>{{ player.league_position }}</span>
          </span>
          <div class="grow text-left">
            {{ player.username }}
            <div
              :class="`${
                player.id === user.id ? 'text-white/60' : 'text-gray-400'
              }`"
            >
              {{ player.name }}
            </div>
          </div>
          <div
            class="absolute -top-[8px] -left-[3px] h-6 w-6 rounded-full bg-white"
            v-if="player.league_position === 1"
          >
            <fa icon="trophy" class="text-yellow-400" />
          </div>
          <div class="text-right">
            <div
              class="text-sm font-semibold"
              :class="`${
                player.id === user.id ? 'text-white' : 'text-gray-400'
              }`"
            >
              Total:
              <span
                class="text-xl font-bold"
                :class="`${
                  player.id === user.id ? 'text-white' : 'text-black'
                }`"
                >{{ player.total_points }}</span
              >
            </div>
            <div class="relative -left-[2px] text-sm">
              Gameweek:
              <span class="font-semibold">{{ player.gw_points }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue"
import { mapState } from "pinia"
import { store } from "@/stores/MainStore.js"

export default {
  name: "Scoreboard",
  components: {
    Header,
  },
  mounted() {
    this.getScoreboardData()
  },
  data() {
    return {
      loading: true,
      league: {},
    }
  },
  computed: {
    ...mapState(store, ["activeLeague", "user"]),
    sortedPlayers() {
      return this.league.players.data
    },
  },
  methods: {
    async getScoreboardData() {
      const leagueResp = await this.$api.getLeague(this.activeLeague.id, [
        "players",
      ])
      const league = leagueResp.data.data

      this.loading = false
      this.league = league
    },
    goToUserPredictions(user) {
      this.$router.push({ name: "user-predictions", params: { user } })
    },
  },
}
</script>
