import mixpanel from "mixpanel-browser"

const debug = false;

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  debug,
  batch_requests: false
})

const track = (event, data) =>
  debug || process.env.NODE_ENV === "production" ? mixpanel.track(event, data) : null

export default track
