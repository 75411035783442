<template>
  <div class="mx-auto h-full w-full bg-zinc-800" id="dashboard">
    <Modal :open="rulesModalOpen" @close="rulesModalToggle" title="How to Play">
      <Rules />
    </Modal>
    <div
      class="fixed top-0 left-0 z-20 h-auto w-full overflow-auto bg-zinc-800 pt-3 shadow-lg shadow-zinc-800/50"
    >
      <DashboardHeader
        @toggleLeaguePanel="toggleLeaguePanel"
        @onGameweekSwitch="onGameweekSwitch"
        :visibleGameweek="visibleGameweek"
        :activeLeague="activeLeague"
        :fixtures="fixtures"
        :panelOpen="leaguePanelOpen"
      />
      <DashboardLeagueSwitcher
        v-if="leaguePanelOpen"
        @switchLeague="switchLeague"
        @share="share"
        @howToPlay="rulesModalToggle"
        :canSwitchLeagues="canSwitchLeagues"
        :activeLeague="activeLeague"
        :canShare="canShare"
      />
      <div
        class="relative mt-4 border-b border-t border-b-white/10 border-t-slate-900"
      ></div>
    </div>
    <div
      :class="[
        'flex',
        'justify-between',
        'mx-6',
        'relative',
        'z-10',
        'transition-all',
        'md:max-w-xl',
        'md:mx-auto',
        `${leaguePanelOpen ? 'pt-[177px]' : 'pt-[110px]'}`,
      ]"
    >
      <DashboardFigure
        :figure="loading ? '--' : gameweekPoints"
        icon="star"
        text="GW Points"
      />
      <DashboardFigure
        :figure="loading ? '--' : totalPoints"
        icon="medal"
        text="Total Points"
      />
      <router-link :to="{ name: 'scoreboard' }">
        <DashboardFigure
          :figure="loading ? '--' : leaguePosition"
          icon="chart-line"
          text="Position"
          icon-right="arrow-right"
        />
      </router-link>
    </div>
    <div class="my-6 px-3">
      <Button
        @click="
          $router.push({
            name: 'set-predictions',
            params: {
              gameweek: visibleGameweek,
              can_use_bonus_coin_default: canUseBonusCoin,
              can_use_bonus_lock_default: canUseBonusLock,
            },
          })
        "
        text="Set Predictions"
        icon-right="arrow-right"
        v-if="showSetPredictionsButton"
      />
    </div>
    <div
      class="relative z-10 mt-3 min-h-full overflow-auto rounded-t-2xl bg-white py-2 text-center shadow"
    >
      <fa
        v-if="loading || refreshing"
        icon="circle-notch"
        size="2x"
        class="mt-16 text-zinc-800"
        spin
      />
      <DashboardFixtures
        v-if="!loading && !refreshing"
        :fixtures="fixtures"
        :predictions="predictions"
        :gameweek="visibleGameweek"
        :can_use_bonus_coin="canUseBonusCoin"
        :can_use_bonus_lock="canUseBonusLock"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment"
import ordinal from "ordinal"
import RendersTeams from "@/mixins/RendersTeams"
import Button from "@/components/Button.vue"
import Modal from "@/components/Modal.vue"
import Rules from "@/components/Rules.vue"
import DashboardFigure from "@/components/DashboardFigure.vue"
import DashboardHeader from "@/components/DashboardHeader.vue"
import DashboardLeagueSwitcher from "@/components/DashboardLeagueSwitcher.vue"
import DashboardFixtures from "@/components/DashboardFixtures.vue"
import { mapWritableState, mapActions, mapState } from "pinia"
import { ACTIVE_LEAGUE_STORAGE_KEY } from "@/constants/app"
import { store } from "@/stores/MainStore.js"
import { sumBy } from "lodash"

export default {
  name: "Dashboard",
  components: {
    Button,
    DashboardFigure,
    DashboardHeader,
    DashboardLeagueSwitcher,
    DashboardFixtures,
    Modal,
    Rules,
  },
  mixins: [RendersTeams],
  mounted() {
    this.checkOngoingSeason()
  },
  created() {
    this.getDashboardData()
  },
  computed: {
    ...mapWritableState(store, ["visibleGameweek", "activeLeague"]),
    ...mapState(store, ["user", "activeGameweek", "config"]),
    gameweekPoints() {
      return sumBy(this.predictions, "points")
    },
    totalPoints() {
      return this.activeLeague.players.data.find(
        (player) => player.id === this.user.id
      ).total_points
    },
    showSetPredictionsButton() {
      if (this.refreshing || this.loading) {
        return false
      }

      // Can only set predictions for the active or next gameweek
      if (
        this.activeGameweek !== this.visibleGameweek &&
        this.visibleGameweek !== this.activeGameweek + 1
      ) {
        return false
      }

      // If games have started, don't show button
      if (
        this.fixtures.find((fixture) => moment(fixture.kickoff_at).isBefore())
      ) {
        return false
      }

      // Check to see if any predictions have not been set
      const predictionsNotSet = this.fixtures.find(
        (fixture) =>
          !fixture.deadline_passed &&
          !this.predictions.find(
            (prediction) => prediction.fixture_id === fixture.id
          )
      )

      return predictionsNotSet
    },
    canSwitchLeagues() {
      return this.user.leagues.data.length > 1
    },
    canShare() {
      return window.navigator.canShare && window.navigator.canShare()
    },
    canUseBonusCoin() {
      return this.predictions.find((prediction) => prediction.bonus_coin_active)
        ? false
        : true
    },
    canUseBonusLock() {
      return this.predictions.find((prediction) => prediction.bonus_lock_active)
        ? false
        : true
    },
  },
  data() {
    return {
      loading: true,
      refreshing: false,
      fixtures: [],
      predictions: [],
      leaguePosition: null,
      leaguePanelOpen: false,
      rulesModalOpen: false,
    }
  },
  methods: {
    ...mapActions(store, ["setVisibleGameweek", "setActiveLeague"]),

    onGameweekSwitch(direction) {
      const visibleGameweek =
        direction === "next"
          ? this.visibleGameweek + 1
          : this.visibleGameweek - 1

      this.refreshing = true
      this.setVisibleGameweek(visibleGameweek)
      this.getDashboardData()
    },
    async getDashboardData() {
      const fixtureResp = await this.$api.getFixtures(this.visibleGameweek, [
        "home_team",
        "away_team",
      ])
      const fixtureData = fixtureResp.data.data

      const predictionResp = await this.$api.getPredictions(
        this.visibleGameweek,
        this.activeLeague.id
      )
      const predictionData = predictionResp.data.data

      const leaguePosition = ordinal(
        this.activeLeague.players.data.find(
          (player) => player.id === this.user.id
        ).league_position
      )

      this.predictions = predictionData
      this.loading = false
      this.refreshing = false
      this.fixtures = fixtureData
      this.leaguePosition = leaguePosition
    },
    switchLeague() {
      const userLeagues = this.user.leagues.data
      const leagueIndex = userLeagues.findIndex(
        (league) => league.id === this.activeLeague.id
      )
      const newActiveLeague = userLeagues[leagueIndex + 1]
        ? userLeagues[leagueIndex + 1]
        : userLeagues[0]

      this.refreshing = true

      this.setActiveLeague(newActiveLeague)
      localStorage.setItem(ACTIVE_LEAGUE_STORAGE_KEY, this.activeLeague.id)

      this.getDashboardData()
    },
    toggleLeaguePanel() {
      this.leaguePanelOpen = !this.leaguePanelOpen
    },
    share() {
      window.navigator.share({
        url: window.location.origin,
        title: "Join predictor league",
        text: `You have been invited to join a nil-nil predictor league - ${this.activeLeague.name}`,
      })
    },
    checkOngoingSeason() {
      if (!this.config.season_ongoing) {
        this.$router.push({
          name: "waiting_for_season",
        })
      }
    },
    rulesModalToggle() {
      this.rulesModalOpen = !this.rulesModalOpen
    },
  },
}
</script>
