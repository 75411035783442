<template>
  <div id="app">
    <div
      v-if="!isMobile"
      class="flex h-full flex-col justify-center bg-zinc-800 p-6 text-center font-semibold text-white"
    >
      <span>
        Sorry, Matchday Predictor isn't quite ready for desktop! Please come
        back on a mobile device to get going.</span
      >
    </div>
    <transition name="fade" mode="out-in" v-if="isMobile">
      <div
        class="flex h-full w-full flex-col justify-center bg-zinc-800"
        v-if="loading"
      >
        <span
          class="font-display mt-2 text-center text-4xl font-bold uppercase text-white"
          >Matchday Predictor</span
        >
        <Loader size="3x" />
        <div class="mt-6 text-center font-semibold text-white md:text-2xl">
          Tying laces...
        </div>
      </div>
      <router-view v-if="!loading" />
    </transition>
  </div>
</template>

<script>
import { isMobile } from "@/helpers/mobile"
import Loader from "@/components/Loader.vue"
import ConfiguresApp from "@/mixins/ConfiguresApp"

export default {
  name: "App",
  data() {
    return {
      loading: true,
      isMobile: isMobile(),
    }
  },
  components: {
    Loader,
  },
  mixins: [ConfiguresApp],
  mounted() {
    this.configureApp(() => (this.loading = false))
  },
}
</script>

<style>
#app {
  height: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
