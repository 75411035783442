var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto h-full w-full bg-slate-900",attrs:{"id":"dashboard"}},[_c('div',{staticClass:"fixed top-0 left-0 z-20 h-auto w-full overflow-auto bg-slate-900 pt-3 shadow-lg shadow-slate-900/50"},[_c('div',{staticClass:"mb-4 ml-3 block text-white",on:{"click":_vm.goBack}},[_c('fa',{staticClass:"mr-2",attrs:{"icon":"arrow-left"}}),_vm._v("Back ")],1),_c('div',{staticClass:"flex items-center justify-between px-3"},[_c('div',[_c('div',{staticClass:"text-lg font-semibold text-amber-400"},[_c('span',[_vm._v(_vm._s(this.user.username))])]),_c('div',{staticClass:"text-white"},[_vm._v("Gameweek "+_vm._s(_vm.visibleGameweek))])]),_c('div',[(_vm.visibleGameweek > 1)?_c('div',{staticClass:"inline-block h-6 w-6 rounded-full bg-slate-700 text-center text-white",on:{"click":function($event){return _vm.onGameweekSwitch('prev')}}},[_c('fa',{staticClass:"relative -top-[2px] -left-[1px] cursor-pointer text-xs",attrs:{"icon":"chevron-left"}})],1):_vm._e(),(_vm.visibleGameweek < _vm.activeGameweek)?_c('div',{staticClass:"ml-3 inline-block h-6 w-6 rounded-full bg-slate-700 text-center text-white",on:{"click":function($event){return _vm.onGameweekSwitch('next')}}},[_c('fa',{staticClass:"relative -top-[2px] left-[1px] cursor-pointer text-xs",attrs:{"icon":"chevron-right"}})],1):_vm._e()])]),_c('div',{staticClass:"relative mt-4 border-b border-t border-b-white/10 border-t-slate-900"})]),_c('div',{class:[
      'flex',
      'justify-center',
      'relative',
      'z-10',
      'transition-all',
      'md:max-w-xl',
      'md:mx-auto',
      'pt-[130px]' ]},[_c('DashboardFigure',{attrs:{"figure":_vm.loading ? '--' : _vm.gameweekPoints,"text":"GW Points"}})],1),_c('div',{staticClass:"relative z-10 mt-3 min-h-full overflow-auto rounded-t-2xl bg-white py-2 text-center shadow"},[(_vm.loading)?_c('fa',{staticClass:"mt-16 text-slate-900",attrs:{"icon":"circle-notch","size":"2x","spin":""}}):_vm._e(),(!_vm.loading)?_c('DashboardFixtures',{attrs:{"fixtures":_vm.fixtures,"predictions":_vm.predictions,"gameweek":_vm.visibleGameweek,"observe":""}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }