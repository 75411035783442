<template>
  <div>
    <div
      class="flex items-center justify-between border-t py-4 text-lg font-bold"
      @click="() => (show.gettingStarted = !show.gettingStarted)"
    >
      Getting started
      <fa :icon="show.gettingStarted ? 'chevron-down' : 'chevron-right'" />
    </div>
    <div :class="['help-panel', show.gettingStarted ? 'open' : '']">
      <div class="mb-4">
        <strong class="block font-semibold">1. Join a league</strong>
        <span class="text-slate-600"
          >Join a league using a league code, or create your own.</span
        >
      </div>
      <div class="mb-4">
        <strong class="block font-semibold">2. Set your predictions</strong
        ><span class="text-slate-600"
          >Set your predictions for each game of the upcoming gameweek. Apply
          your chips for added perks!</span
        >
      </div>
      <div class="pb-6">
        <strong class="block font-semibold">3. Earn points</strong
        ><span class="text-slate-600"
          >Earn points for each prediction you correctly guess. You'll earn
          points for the correct score, goal difference or outright
          winner.</span
        >
      </div>
    </div>
    <div
      class="flex items-center justify-between border-t py-4 text-lg font-bold"
      @click="() => (show.rules = !show.rules)"
    >
      Rules
      <fa :icon="show.rules ? 'chevron-down' : 'chevron-right'" />
    </div>
    <div :class="['help-panel', show.rules ? 'open' : '']">
      <Rules />
    </div>
  </div>
</template>

<script>
import Rules from "@/components/Rules"

export default {
  components: {
    Rules,
  },
  data() {
    return {
      show: {
        gettingStarted: false,
        rules: false,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.help-panel {
  overflow: auto;
  max-height: 0;
  transition: max-height 0.2s ease-out;

  &.open {
    max-height: 1000px;
  }
}
</style>
