<template>
  <div
    class="fixed top-0 left-0 z-[999] h-full w-full overflow-scroll bg-white pt-10"
    :class="{ hidden: !open }"
    id="modal"
  >
    <div>
      <fa icon="times" class="absolute right-4 top-3" @click="$emit('close')" />
      <h1 class="border-b px-3 pb-3 text-xl font-semibold">{{ title }}</h1>
    </div>
    <div class="overflow-scroll p-3">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Modal",
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
