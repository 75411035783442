import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import api from "./api"
import "./assets/styles/app.css"
import "./assets/tailwind.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import VuePageTitle from "vue-page-title"
import { createPinia, PiniaVuePlugin } from "pinia"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

import {
  faCircleNotch,
  faCoins,
  faStar,
  faChartLine,
  faMedal,
  faFutbol,
  faExclamationCircle,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faArrowRight,
  faArrowLeft,
  faTrophy,
  faExchangeAlt,
  faShareAlt,
  faLock,
  faUsers,
  faCode,
  faTimes,
  faQuestionCircle,
  faCopy,
  faBook,
} from "@fortawesome/free-solid-svg-icons"

import "./registerServiceWorker"

library.add(
  faCircleNotch,
  faCoins,
  faStar,
  faChartLine,
  faMedal,
  faFutbol,
  faExclamationCircle,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faArrowRight,
  faArrowLeft,
  faTrophy,
  faExchangeAlt,
  faShareAlt,
  faLock,
  faUsers,
  faCode,
  faTimes,
  faQuestionCircle,
  faCopy,
  faBook
)

Sentry.init({
  Vue,
  dsn: "https://e3eb22ae40f847c79df132616d114e45@o1176132.ingest.sentry.io/4504033116094464",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "matchdaypredictor.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

Vue.config.productionTip = false
Vue.prototype.$api = api

Vue.use(VuePageTitle, {
  router,
  prefix: "Matchday Predictor - ",
})
Vue.component("fa", FontAwesomeIcon)
Vue.use(PiniaVuePlugin)

const pinia = createPinia()

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app")
