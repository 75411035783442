<template>
  <div :class="['mx-3', 'mt-5']">
    <span class="mr-4 mb-2 block">
      <span class="text-white text-white/50">
        League Code:
        <span class="text-white">{{ this.activeLeague.code }}</span>
        <fa icon="copy" @click="copyCode" class="ml-2 text-white" />
        <span class="ml-2 text-xs" v-if="codeCopied">code copied</span>
      </span>
    </span>
    <span
      @click="$emit('switchLeague')"
      v-if="canSwitchLeagues"
      class="mr-4 mb-2 inline-block"
    >
      <fa icon="exchange-alt" class="relative -ml-[1px] mr-1 text-white/50" />
      <a href="#" class="text-white underline">Switch League</a>
    </span>
    <span
      @click="$emit('share')"
      v-if="canShare"
      class="mr-4 mb-2 inline-block"
    >
      <fa icon="share-alt" class="relative -ml-[1px] mr-1 text-white/50" />
      <a href="#" class="text-white">Share</a>
    </span>
    <span
      @click="$router.push({ name: 'home', query: { skip_redirect: 'true' } })"
      class="mr-4 mb-2 inline-block"
    >
      <fa icon="medal" class="relative -ml-[1px] mr-1 text-white/50" />
      <a href="#" class="text-white underline">Join League</a>
    </span>
    <div @click="$emit('howToPlay')" class="mr-4 mb-2">
      <fa icon="book" class="relative -ml-[1px] mr-1 text-white/50" />
      <a href="#" class="text-white underline">How to play</a>
    </div>
  </div>
</template>

<script>
import copy from "copy-to-clipboard"

export default {
  props: {
    canSwitchLeagues: {
      type: Boolean,
      default: false,
    },
    canShare: {
      type: Boolean,
      default: false,
    },
    activeLeague: {
      type: Object,
    },
  },
  data() {
    return {
      codeCopied: false,
    }
  },
  methods: {
    copyCode() {
      copy(this.activeLeague.code)
      this.codeCopied = true
      setTimeout(() => (this.codeCopied = false), 2000)
    },
  },
}
</script>

<style lang="scss" scoped></style>
