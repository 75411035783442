<template>
  <div class="text-center">
    <fa v-if="icon" :icon="icon" size="lg" class="mb-1 text-yellow-500" />
    <h1 class="text-3xl font-semibold text-white">
      {{ figure }}
      <fa
        :icon="iconRight"
        class="relative -top-[3px] text-sm"
        v-if="iconRight"
      ></fa>
    </h1>
    <div class="text-sm text-white/50">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    figure: {
      type: [Number, String],
      required: true,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
      required: false,
    },
    iconRight: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped></style>
