<template>
  <div>
    <div class="mb-4">
      <strong class="mb-2 block font-bold">Setting Predictions</strong>
      <div class="text-sm">
        Set your predictions for each fixture of the upcoming gameweek up to 1
        hour before the match kicks off. You can change your mind as many times
        as you like.
      </div>
    </div>
    <div class="mb-4">
      <div class="mb-3 border-b"></div>
      <strong class="mb-2 block font-bold">Chips</strong>
      <div class="">
        <p class="mb-3 text-sm">
          Every gameweek you will be given two 'chips' to use on a fixture of
          your choice:
        </p>
        <p class="mb-3 text-sm">
          <fa icon="lock" class="mr-1 text-amber-600" /><strong
            class="text-black"
            >Lock</strong
          >
          - Applying the lock will prevent you from losing points should you get
          the score completely wrong. See below to learn how points are earned.
        </p>
        <p class="text-sm">
          <fa icon="coins" class="mr-1 text-amber-600" /><strong
            class="text-black"
            >Coin</strong
          >
          - Applying your coin will multiply any points earned for that
          particular fixture. Use wisely! You can use both chips on seperate
          fixtures, or decide to stack them on a single fixture if you decide.
        </p>
      </div>
    </div>
    <div class="pb-8">
      <div class="mb-3 border-b"></div>
      <strong class="mb-2 block font-bold">Points</strong>
      <div class="">
        <p class="mb-3 text-sm">Points can be earned or lost as follows:</p>
        <p class="mb-3 text-sm">
          <strong class="text-black">Exact score</strong>
          Earn <strong>30</strong> points if you predict the exact score.
        </p>
        <p class="mb-3 text-sm">
          <strong class="text-black">Goal difference</strong>
          Earn <strong>15</strong> points if you predict the goal difference.
          Eg, if a game finishes 2-1 and you predicted 3-2, you will gain goal
          difference points.
        </p>
        <p class="mb-3 text-sm">
          <strong class="text-black">Correct winner</strong>
          Earn <strong>5</strong> points if you get the score wrong but predict
          the correct winner.
        </p>
        <p class="text-sm">
          <strong class="text-black">Incorrect</strong>
          Lose <strong>5</strong> points if you fail to get the correct score,
          goal different or winner.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
