<template>
  <div class="mx-auto h-full w-full bg-zinc-800">
    <Header title="Login" />
    <div class="h-3/4 rounded-t-2xl bg-white p-6">
      <div class="sm:mx-auto sm:w-2/3 sm:pt-2 lg:w-1/2">
        <form autocomplete="off" id="login_form">
          <text-input
            name="email"
            variant="light"
            label="Email address"
            placeholder="Email address"
            :classes="['w-full']"
            :error="getError('email')"
            v-model="email"
          />
          <text-input
            name="password"
            variant="light"
            label="Password"
            placeholder="Password"
            :classes="['w-full']"
            v-model="password"
            type="password"
          />
        </form>
        <Button
          text="Continue"
          :classes="['mt-4 mb-4']"
          :disabled="!canSubmit"
          :loading="loading"
          @click="onSubmit"
          icon-right="arrow-right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue"
import Button from "@/components/Button.vue"
import HandlesFormRequests from "@/mixins/HandlesFormRequests"
import HandlesLogin from "@/mixins/HandlesLogin"
import HandlesJoinLeague from "@/mixins/HandlesJoinLeague"
import ConfiguresApp from "@/mixins/ConfiguresApp"
import Header from "@/components/layout/Header.vue"
import {
  LOGIN_CONTEXT_JOIN,
  LOGIN_CONTEXT_CREATE,
  LOGIN_CONTEXT_DASHBOARD,
} from "@/constants/app"
import { store } from "@/stores/MainStore.js"
import { mapState } from "pinia"
import { isEmpty } from "lodash"

export default {
  name: "Login",
  components: {
    Button,
    TextInput,
    Header,
  },
  mixins: [HandlesFormRequests, HandlesLogin, HandlesJoinLeague, ConfiguresApp],
  computed: {
    ...mapState(store, ["ongoingLeagueJoin"]),
    canSubmit() {
      return !this.loading && !isEmpty(this.email) && !isEmpty(this.password)
    },
  },
  props: {
    redirect: {
      type: Object,
    },
    context: {
      type: String,
      required: false,
      default: LOGIN_CONTEXT_DASHBOARD,
    },
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      this.resetErrors()

      await this.login(this.email, this.password).catch((response) => {
        this.handleErrorResponse(response)
        this.loading = false
      })

      if (!isEmpty(this.errors)) {
        return
      }

      if (this.context === LOGIN_CONTEXT_JOIN) {
        await this.joinLeague()
        return
      }

      if (this.context === LOGIN_CONTEXT_CREATE) {
        this.$router.push({ name: "create-league" })
        return
      }

      this.configureApp(() => {
        this.$router.push({ name: "dashboard" })
      })
    },
  },
}
</script>
