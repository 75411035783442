import { defineStore } from "pinia"

export const store = defineStore("main", {
  state: () => {
    return {
      config: null,
      user: null,
      visibleGameweek: null,
      activeGameweek: null,
      activeLeague: null,
      ongoingLeagueJoin: {},
      source: null,
    }
  },
  actions: {
    setConfig(config) {
      this.config = config
    },
    setUser(user) {
      this.user = user
    },
    setVisibleGameweek(visibleGameweek) {
      this.visibleGameweek = visibleGameweek
    },
    setActiveGameweek(activeGameweek) {
      this.activeGameweek = activeGameweek
    },
    setActiveLeague(activeLeague) {
      this.activeLeague = activeLeague
    },
    setOngoingLeagueJoin(leagueId, leagueName, username) {
      this.ongoingLeagueJoin = {
        leagueId,
        leagueName,
        username,
      }
    },
    setSource(source) {
      this.source = source
    },
  },
})
