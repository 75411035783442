<template>
  <div class="w-full">
    <div
      v-if="label"
      :class="[
        'text-xs',
        'mb-1',
        'font-semibold',
        'uppercase',
        variant === 'light' ? 'text-gray-900' : 'text-white',
        error
          ? variant === 'light'
            ? 'text-rose-500'
            : 'text-yellow-400'
          : variant === 'light'
          ? 'text-zinc-800'
          : 'text-white',
      ]"
    >
      {{ label }}
    </div>
    <input
      autocomplete="off"
      :type="type"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      :class="[
        'block',
        'p-2 py-3',
        'rounded-md',
        'focus:border-white-400',
        'focus:ring-slate-400',
        'focus:ring',
        'focus:ring-opacity-50',
        'placeholder:text-sm',
        'placeholder:text-gray-400',
        'bg-gray-200',
        error
          ? variant === 'light'
            ? 'border-rose-500'
            : 'border-yellow-400'
          : variant === 'light'
          ? 'border-0'
          : 'border-white',
        ...classes,
      ]"
      @input="$emit('input', $event.target.value)"
    />
    <small class="text-gray-400" v-if="helpText">{{ helpText }}</small>
    <div
      :class="[
        variant === 'light' ? 'text-rose-500' : 'text-yellow-400',
        'text-sm',
        'h-4',
        'mb-4',
        ...errorClasses,
      ]"
    >
      <div v-if="error">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  computed: {
    errorColor() {
      return this.variant === "light" ? "rose-500" : "yellow-500"
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    helpText: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    variant: {
      type: String,
      default: "light",
    },
    classes: {
      type: Array,
      default: () => [],
    },
    errorClasses: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [String, null],
    },
  },
}
</script>
