import axios from "axios"

axios.defaults.baseURL = process.env.VUE_APP_API_HOST + "/"
axios.defaults.withCredentials = true
axios.defaults.headers.common["Accepts"] = "application/json"

window["axios"] = axios

export default {
  setCSFR: () => window.axios.get("sanctum/csrf-cookie"),

  getUser: (includes) =>
    window.axios.get(`user${includes ? "?include=" + includes.join(",") : ""}`),

  login: (data) => window.axios.post("login", { ...data, remember: true }),

  register: (data) => window.axios.post("register", data),

  checkLeague: (data) => window.axios.post("check-league", data),

  getConfig: () => window.axios.get("config"),

  joinLeague: (league_id, data) =>
    window.axios.post(`leagues/${league_id}/join`, data),

  getLeagues: (includes) =>
    window.axios.get(
      `leagues${includes ? "?include=" + includes.join(",") : ""}`
    ),

  getLeague: (leagueID, includes) =>
    window.axios.get(
      `leagues/${leagueID}${includes ? "?include=" + includes.join(",") : ""}`
    ),

  createLeague: (data) => window.axios.post(`leagues`, data),

  getPredictions: (gameweek, league_id, user_id) =>
    window.axios.get(
      `predictions/${league_id}/${gameweek ?? ""}` +
        (user_id ? `?user_id=${user_id}` : "")
    ),

  getActiveGameweek: () => window.axios.get(`active-gameweek`),

  savePrediction: (fixtureID, data) =>
    window.axios.post(`fixtures/${fixtureID}/set-prediction`, data),

  getFixtures: (gameweek, includes) =>
    window.axios.get(
      `fixtures/${gameweek ?? ""}${
        includes ? "?include=" + includes.join(",") : ""
      }`
    ),
}
