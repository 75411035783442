var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mx-auto h-full w-full bg-zinc-800"},[_c('Header',{attrs:{"title":"Leaderboard","sub-title":_vm.league.name,"back-route":{ name: 'dashboard' }}}),_c('div',{staticClass:"h-3/4 rounded-t-2xl bg-white pt-1 text-center"},[(_vm.loading)?_c('fa',{staticClass:"mx-auto mt-16 text-zinc-800",attrs:{"icon":"circle-notch","size":"2x","spin":""}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"pb-1"},_vm._l((_vm.sortedPlayers),function(player){return _c('div',{key:player.id,class:[
          'flex',
          'items-center',
          'last:mb-8',
          'pt-3',
          'pb-4',
          'px-4',
          'm-3',
          'rounded-2xl',
          'relative',
          player.id === _vm.user.id
            ? 'bg-gradient-to-r from-teal-500 to-teal-400'
            : 'bg-gray-200',
          player.id === _vm.user.id ? 'text-white' : 'text-black' ],on:{"click":function($event){return _vm.goToUserPredictions(player)}}},[_c('span',{staticClass:"mr-6 flex items-center font-bold"},[_c('span',{staticClass:"mr-2 text-sm"},[(player.movement === 'up')?_c('fa',{staticClass:"text-green-600",attrs:{"icon":"chevron-up"}}):_vm._e(),(player.movement === 'down')?_c('fa',{staticClass:"text-red-600",attrs:{"icon":"chevron-down"}}):_vm._e(),(player.movement === null)?_c('span',{class:[
                'rounded-full',
                'w-2',
                'h-2',
                'inline-block',
                player.id === _vm.user.id ? 'bg-white' : 'bg-gray-300' ]}):_vm._e()],1),_c('span',[_vm._v(_vm._s(player.league_position))])]),_c('div',{staticClass:"grow text-left"},[_vm._v(" "+_vm._s(player.username)+" "),_c('div',{class:("" + (player.id === _vm.user.id ? 'text-white/60' : 'text-gray-400'))},[_vm._v(" "+_vm._s(player.name)+" ")])]),(player.league_position === 1)?_c('div',{staticClass:"absolute -top-[8px] -left-[3px] h-6 w-6 rounded-full bg-white"},[_c('fa',{staticClass:"text-yellow-400",attrs:{"icon":"trophy"}})],1):_vm._e(),_c('div',{staticClass:"text-right"},[_c('div',{staticClass:"text-sm font-semibold",class:("" + (player.id === _vm.user.id ? 'text-white' : 'text-gray-400'))},[_vm._v(" Total: "),_c('span',{staticClass:"text-xl font-bold",class:("" + (player.id === _vm.user.id ? 'text-white' : 'text-black'))},[_vm._v(_vm._s(player.total_points))])]),_c('div',{staticClass:"relative -left-[2px] text-sm"},[_vm._v(" Gameweek: "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(player.gw_points))])])])])}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }