import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import Login from "../views/Login.vue"
import Register from "../views/Register.vue"
import Join from "../views/Join.vue"
import CreateLeague from "../views/CreateLeague.vue"
import Dashboard from "../views/Dashboard.vue"
import Scoreboard from "../views/Scoreboard.vue"
import SetPredictions from "../views/SetPredictions.vue"
import UserPredictions from "../views/UserPredictions.vue"
import WaitingForSeason from "../views/WaitingForSeason.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      secure: false,
      title: "Home",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    props: true,
    meta: {
      secure: false,
      auth_redirect: true,
      title: "Login",
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    props: true,
    meta: {
      secure: false,
      auth_redirect: true,
      title: "Register",
    },
  },
  {
    path: "/create-league",
    name: "create-league",
    component: CreateLeague,
    props: true,
    meta: {
      secure: false,
      title: "Create League",
    },
  },
  {
    path: "/join/:league_slug",
    name: "join",
    component: Join,
    props: true,
    meta: {
      secure: false,
      title: "Join League",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      secure: true,
      title: "Dashboard",
    },
  },
  {
    path: "/scoreboard",
    name: "scoreboard",
    component: Scoreboard,
    props: true,
    meta: {
      secure: true,
      title: "Scoreboard",
    },
  },
  {
    path: "/set-predictions",
    name: "set-predictions",
    component: SetPredictions,
    props: true,
    meta: {
      secure: true,
      title: "Set Predictions",
    },
  },
  {
    path: "/user-predictions",
    name: "user-predictions",
    component: UserPredictions,
    props: true,
    meta: {
      secure: true,
      title: "User Predictions",
    },
  },
  {
    path: "/waiting",
    name: "waiting_for_season",
    component: WaitingForSeason,
    props: true,
    meta: {
      secure: true,
      title: "Waiting for season to start",
    },
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
})

export default router
