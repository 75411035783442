<template>
    <label :for="name" :class="['flex', 'cursor-pointer', 'items-center', {'opacity-20': disabled}]">
      <div class="relative">
        <input
          type="checkbox"
          :id="name"
          class="sr-only"
          :checked="checked"
          @change="$emit('change', $event.target.checked)"
          :disabled="disabled"
        />
        <div class="block h-8 w-14 rounded-full bg-gray-500"></div>
        <div
          class="dot absolute left-1 top-1 h-6 w-6 rounded-full bg-white transition"
        ></div>
      </div>
      <div class="ml-3 font-medium text-gray-700" v-if="text">{{ text }}</div>
    </label>
</template>

<script>
export default {
  name: "Toggle",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    text: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
input:checked {
  ~ .block {
    background-color: rgb(16, 185, 129) !important;
  }
  ~ .dot {
    transform: translateX(100%);
  }
}
</style>
