var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.fixtures),function(fixture){return _c('div',{key:fixture.id,staticClass:"relative border-b border-gray-200 px-2 pt-3 pb-4 text-sm last:mb-8 last:border-none",on:{"click":function () { return _vm.onFixtureClick(fixture); }}},[_c('div',{staticClass:"mb-1 text-xs font-semibold text-gray-400"},[_vm._v(" "+_vm._s(_vm.formatTime(fixture.kickoff_at))+" ")]),_c('div',{staticClass:"mb-3 flex items-center justify-between"},[_c('div',{staticClass:"flex w-1/2 items-center justify-end text-right font-semibold"},[_c('TeamEmblem',{attrs:{"team":fixture.home_team.data,"position":"right"}})],1),_c('div',{staticClass:"mx-6 font-semibold"},[_c('div',{staticClass:"mb-1"},[_c('span',[_vm._v(_vm._s(fixture.score_h !== null ? fixture.score_h : "-"))]),_c('span',{staticClass:"mx-1"},[_vm._v(":")]),_c('span',[_vm._v(_vm._s(fixture.score_a !== null ? fixture.score_a : "-"))])])]),_c('div',{staticClass:"flex w-1/2 items-center text-left font-semibold"},[_c('TeamEmblem',{attrs:{"team":fixture.away_team.data,"position":"left"}})],1)]),(_vm.predictionSetForFixture(fixture))?_c('div',{class:[
        'text-xs',
        'inline-block',
        'mx-auto',
        'min-w-[40px]',
        'p-1',
        'uppercase',
        'font-semibold',
        'text-white',
        'bg-blue-400',
        'border',
        'border-white' ]},[_vm._v(" "+_vm._s(_vm.getPredictionText(fixture))+" ")]):_vm._e(),(_vm.getPredictionForFixture(fixture).bonus_lock_active)?_c('div',{class:[
        'text-xs',
        'inline-block',
        'mx-auto',
        'p-1',
        'min-w-[30px]',
        'uppercase',
        'font-semibold',
        'text-white',
        'bg-red-400',
        'border',
        'border-white' ]},[_c('fa',{attrs:{"icon":"lock"}})],1):_vm._e(),(_vm.getPredictionForFixture(fixture).bonus_coin_active)?_c('div',{class:[
        'text-xs',
        'inline-block',
        'mx-auto',
        'p-1',
        'min-w-[30px]',
        'uppercase',
        'font-semibold',
        'text-white',
        'bg-amber-500',
        'border',
        'border-white' ]},[_c('fa',{attrs:{"icon":"coins"}})],1):_vm._e(),(_vm.hasPointsForFixture(fixture))?_c('div',{class:[
        'text-xs',
        'inline-block',
        'mx-auto',
        'min-w-[40px]',
        'p-1',
        'uppercase',
        'font-semibold',
        'text-white',
        'border',
        'border-white',
        _vm.getBackgroundColorForPointsPill(fixture) ]},[_vm._v(" "+_vm._s(_vm.getPointsText(fixture))+" ")]):_vm._e(),(_vm.canSetPrediction(fixture))?_c('div',{class:[
        'text-xs',
        'inline-block',
        'mx-auto',
        'py-1',
        'px-2',
        'uppercase',
        'font-semibold',
        'text-white',
        'border',
        'border-white',
        'bg-primary-500' ]},[_vm._v(" "+_vm._s(_vm.hasSetPrediction(fixture) ? "Update" : "Set")+" "),_c('fa',{attrs:{"icon":"arrow-right"}})],1):_vm._e(),(fixture.is_postponed)?_c('div',{staticClass:"mt-2 rounded bg-rose-500 py-[2px] text-xs text-white"},[_c('fa',{staticClass:"mr-2",attrs:{"icon":"exclamation-circle"}}),_vm._v(" This game may be postponed ")],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }